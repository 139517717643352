html {
	overflow: hidden;
}

.App {
	display: relative;
	font-family: Helvetica;
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	overflow: hidden;
	background: #EEFFFF;
}

.App::after {
	content: "";
	/* Background pattern from Toptal Subtle Patterns */
	background: url(./Images/Texture.png);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: .1;
	z-index: 0;
}

@media only screen and (min-width: 1200px) {
	#headshot {
		width: 80px;
		height: 80px;
	}

	#statement {
		font-size: 15.2px;
	}
}

@media only screen and (max-width: 1200px) {
	#first, #second {
		font-size: 55px;
	}

	#statement {
		font-size: 14px;
		width: 380px;
	}

	#headshot {
		margin-right: 15px;
		margin-bottom: 15px;
		width: 70px;
  		height: 70px;
	}

	#cardViewer h1 {
		font-size: 15px;
	}

	#cardViewer h2 {
		font-size: 12px;
	}

	#github-icon {
		font-size: 10px;
		bottom: 4%;
	}

	#resume-label {
		font-size: 16px;
	}

	#github-label {
		font-size: 16px;
	}

}

@media only screen and (min-width: 900px) {
	.App {
		display: grid;
		grid-template-columns: 40% 60%;
		grid-template-areas: "info cards";
	}
}


@media only screen and (max-width: 900px) {

	#project-info {
		font-size: 8px;
		bottom: 5%;
	}

	#cardViewer {
		grid-area: cards;
		position: absolute;
		top: 210px;
		z-index: 1;
		padding: 0;
		bottom: 0;
	}

	.card {
		margin-top: 5%;
		width: 90%;
		right: 0;
	}

	#github-icon {
		font-size: 9px;
		bottom: 6%;
	}

	#personal-info {
		position: relative;
		width: 475px;
		display: block;
		margin: 0 auto;
		left: 0;
		top: 0;
	}

	#personal-info-container {
		position: fixed;
		top: 0;
		margin: 0;
		background-color: #F9FFFD;
		border-bottom: 1px solid lightgray;
		height: 210px;
		width: 100%;
		z-index: 2;
	}

	#statement {
		margin-top: 15px;
		font-size: 12px;
		width: 470px;
	}

	#info-github-icon {
		width: 20px;
	}

	#file-icon {
		width: 15px;
	}

	#links {
		margin-top: -5px;
	}

	#headshot {
		margin-top: 15px;
		margin-left: 5px;
		width: 50px;
  		height: 50px;
  	}

  	#first {
		margin-left: .5%;
	}

	#first, #second {
		font-size: 30px;
		margin-left: 10px;
	}

	#name-headshot {
		margin-top: 5%;
	}
}


@media only screen and (max-width: 600px) {
	#statement {
		width: 320px;
	}

	#statement2 {
		display: none;
	}

	#personal-info-container {
		height: 178px;
	}

	#personal-info {
		width: 310px;
	}

	#headshot {
		margin-bottom: 2px;
	}

	#info-github-icon {
		width: 20px;
	}

	#file-icon {
		width: 15px;
	}

	#project-info {
		font-size: 8px;
		bottom: 3%;
	}

	#github-icon {
		font-size: 7px;
		bottom: 4.5%;
	}

	#cardViewer {
		top: 178px;
	}

	#project-info h1, #project-info h2 {
		font-size: 10px;
	}
}