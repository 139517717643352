#cardViewer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	grid-area: cards;
	overflow: auto;
	padding-top: 10%;
	padding-bottom: 10%;
	z-index: 1;
}