#headshot {
	border-radius: 50%;
	width: 80px;
	height: 80px;
	object-fit: cover;
	object-position: 100% 12%;
	margin-top: 20px;
	margin-left: 5px;
	float: left;
	margin-right: 20px;
	margin-bottom: 10px;
	shape-outside: circle(50%);
}

#first {
	display: block;
	font-size: 65px;
	text-transform: uppercase;
	font-family: Helvetica;
	margin: 0;
	line-height: 85%;
	font-weight: bolder;
	margin-left: 7px;
}

#second {
	display: block;
	margin: 0;
	font-size: 65px;
	text-transform: uppercase;
	font-family: Helvetica;
	line-height: 85%;
	font-weight: bolder;
}

#links {
	margin-top: 20px;
}

#name-headshot {
	margin-top: 10%;
}

#personal-info-container {
	position: relative;
	top: 15%;
	grid-area: info;
	z-index: 1;
	width: 100%;
	height: 100vh;
}

#personal-info {
	position: relative;
	top: 7%;
	left: 7%;
}

#info-location {
	font-weight: bold;
}

#icons {
	margin-left: 115%;
	margin-top: 10px;
}

#statement {
	width: 500px;
	margin-top: 20px;
	font-size: 14.7px;
}

#statement2 {
	margin-top: 10px;
}

#info-github-icon {
	width: 20px;
}

#github-label {
	text-decoration: none;
	color: black;
	line-height: 50px;
}

#github-label span {
	margin-left: 27px;
}

#links li {
	list-style-type: none;
	display: inline;
}

#links {
	padding: 0;
	margin-top: 5px;
}

#resume-label {
	text-decoration: none;
	color: black;
	margin-left: 20px;
}

#resume-label span {
	margin-left: 22px;
}

#file-icon {
	width: 15px;
}


#file-icon:hover {
	transform: scale(1.1)
}

#info-github-icon:hover {
	transform: scale(1.1);
}

p {
	margin: 0;
}
	
#info-github-icon, #file-icon {
	color: black;
	transition: transform .1s ease-in;
	position: absolute;
}