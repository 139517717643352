body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#headshot {
	border-radius: 50%;
	width: 80px;
	height: 80px;
	object-fit: cover;
	object-position: 100% 12%;
	margin-top: 20px;
	margin-left: 5px;
	float: left;
	margin-right: 20px;
	margin-bottom: 10px;
	-webkit-shape-outside: circle(50%);
	        shape-outside: circle(50%);
}

#first {
	display: block;
	font-size: 65px;
	text-transform: uppercase;
	font-family: Helvetica;
	margin: 0;
	line-height: 85%;
	font-weight: bolder;
	margin-left: 7px;
}

#second {
	display: block;
	margin: 0;
	font-size: 65px;
	text-transform: uppercase;
	font-family: Helvetica;
	line-height: 85%;
	font-weight: bolder;
}

#links {
	margin-top: 20px;
}

#name-headshot {
	margin-top: 10%;
}

#personal-info-container {
	position: relative;
	top: 15%;
	grid-area: info;
	z-index: 1;
	width: 100%;
	height: 100vh;
}

#personal-info {
	position: relative;
	top: 7%;
	left: 7%;
}

#info-location {
	font-weight: bold;
}

#icons {
	margin-left: 115%;
	margin-top: 10px;
}

#statement {
	width: 500px;
	margin-top: 20px;
	font-size: 14.7px;
}

#statement2 {
	margin-top: 10px;
}

#info-github-icon {
	width: 20px;
}

#github-label {
	text-decoration: none;
	color: black;
	line-height: 50px;
}

#github-label span {
	margin-left: 27px;
}

#links li {
	list-style-type: none;
	display: inline;
}

#links {
	padding: 0;
	margin-top: 5px;
}

#resume-label {
	text-decoration: none;
	color: black;
	margin-left: 20px;
}

#resume-label span {
	margin-left: 22px;
}

#file-icon {
	width: 15px;
}


#file-icon:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1)
}

#info-github-icon:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

p {
	margin: 0;
}
	
#info-github-icon, #file-icon {
	color: black;
	transition: -webkit-transform .1s ease-in;
	transition: transform .1s ease-in;
	transition: transform .1s ease-in, -webkit-transform .1s ease-in;
	position: absolute;
}
#cardViewer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	grid-area: cards;
	overflow: auto;
	padding-top: 10%;
	padding-bottom: 10%;
	z-index: 1;
}
.card {
	width: 80%;
	height: auto;
	border-radius: 2%;
	background-color: #E2DEDE;
	margin-top: 10%;
	margin-bottom: 5%;
	position: relative;
	-webkit-filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, .25));
	        filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, .25));
	padding-bottom: 10%;
	padding-top: 5%;
	z-index: 1;
}

h1 {
	margin: 0;
	margin-bottom: 2px;
	font-size: 20px;
	font-weight: bold;
}

h2 {
	margin: 0;
	font-size: 15px;
	font-weight: lighter;
}

#card-header {
	position: absolute;
	width: 85%;
	top: 3.5%;
	left: 7%;
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	font-weight: 700;
}

#project-title {
	color: black;
}

#card-index {
	color: #7B7B7B;
}

#project-info {
	position: absolute;
	width: 85%;
	bottom: 3%;
	left: 7%;
	font-size: 12px;
	color: black;
}

#screen {
	width: 85%;
	-webkit-perspective: 100px;
	        perspective: 100px;
	position: relative;
	top: 3%;
	left: 7.5%;
	-webkit-filter: drop-shadow(2px 5px 5px rgb(0, 0, 0, .25));
	        filter: drop-shadow(2px 5px 5px rgb(0, 0, 0, .25));
}

#github-icon {
	position: absolute;
	bottom: 3%;
	right: 8%;
	font-size: 13px;
	color: black;
}

@media only screen and (max-width: 900px) {
	.card {
		padding-top: 10%;
	}
}
html {
	overflow: hidden;
}

.App {
	display: relative;
	font-family: Helvetica;
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	overflow: hidden;
	background: #EEFFFF;
}

.App::after {
	content: "";
	/* Background pattern from Toptal Subtle Patterns */
	background: url(/static/media/Texture.6eda72ff.png);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: .1;
	z-index: 0;
}

@media only screen and (min-width: 1200px) {
	#headshot {
		width: 80px;
		height: 80px;
	}

	#statement {
		font-size: 15.2px;
	}
}

@media only screen and (max-width: 1200px) {
	#first, #second {
		font-size: 55px;
	}

	#statement {
		font-size: 14px;
		width: 380px;
	}

	#headshot {
		margin-right: 15px;
		margin-bottom: 15px;
		width: 70px;
  		height: 70px;
	}

	#cardViewer h1 {
		font-size: 15px;
	}

	#cardViewer h2 {
		font-size: 12px;
	}

	#github-icon {
		font-size: 10px;
		bottom: 4%;
	}

	#resume-label {
		font-size: 16px;
	}

	#github-label {
		font-size: 16px;
	}

}

@media only screen and (min-width: 900px) {
	.App {
		display: grid;
		grid-template-columns: 40% 60%;
		grid-template-areas: "info cards";
	}
}


@media only screen and (max-width: 900px) {

	#project-info {
		font-size: 8px;
		bottom: 5%;
	}

	#cardViewer {
		grid-area: cards;
		position: absolute;
		top: 210px;
		z-index: 1;
		padding: 0;
		bottom: 0;
	}

	.card {
		margin-top: 5%;
		width: 90%;
		right: 0;
	}

	#github-icon {
		font-size: 9px;
		bottom: 6%;
	}

	#personal-info {
		position: relative;
		width: 475px;
		display: block;
		margin: 0 auto;
		left: 0;
		top: 0;
	}

	#personal-info-container {
		position: fixed;
		top: 0;
		margin: 0;
		background-color: #F9FFFD;
		border-bottom: 1px solid lightgray;
		height: 210px;
		width: 100%;
		z-index: 2;
	}

	#statement {
		margin-top: 15px;
		font-size: 12px;
		width: 470px;
	}

	#info-github-icon {
		width: 20px;
	}

	#file-icon {
		width: 15px;
	}

	#links {
		margin-top: -5px;
	}

	#headshot {
		margin-top: 15px;
		margin-left: 5px;
		width: 50px;
  		height: 50px;
  	}

  	#first {
		margin-left: .5%;
	}

	#first, #second {
		font-size: 30px;
		margin-left: 10px;
	}

	#name-headshot {
		margin-top: 5%;
	}
}


@media only screen and (max-width: 600px) {
	#statement {
		width: 320px;
	}

	#statement2 {
		display: none;
	}

	#personal-info-container {
		height: 178px;
	}

	#personal-info {
		width: 310px;
	}

	#headshot {
		margin-bottom: 2px;
	}

	#info-github-icon {
		width: 20px;
	}

	#file-icon {
		width: 15px;
	}

	#project-info {
		font-size: 8px;
		bottom: 3%;
	}

	#github-icon {
		font-size: 7px;
		bottom: 4.5%;
	}

	#cardViewer {
		top: 178px;
	}

	#project-info h1, #project-info h2 {
		font-size: 10px;
	}
}
