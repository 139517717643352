.card {
	width: 80%;
	height: auto;
	border-radius: 2%;
	background-color: #E2DEDE;
	margin-top: 10%;
	margin-bottom: 5%;
	position: relative;
	filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, .25));
	padding-bottom: 10%;
	padding-top: 5%;
	z-index: 1;
}

h1 {
	margin: 0;
	margin-bottom: 2px;
	font-size: 20px;
	font-weight: bold;
}

h2 {
	margin: 0;
	font-size: 15px;
	font-weight: lighter;
}

#card-header {
	position: absolute;
	width: 85%;
	top: 3.5%;
	left: 7%;
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	font-weight: 700;
}

#project-title {
	color: black;
}

#card-index {
	color: #7B7B7B;
}

#project-info {
	position: absolute;
	width: 85%;
	bottom: 3%;
	left: 7%;
	font-size: 12px;
	color: black;
}

#screen {
	width: 85%;
	perspective: 100px;
	position: relative;
	top: 3%;
	left: 7.5%;
	filter: drop-shadow(2px 5px 5px rgb(0, 0, 0, .25));
}

#github-icon {
	position: absolute;
	bottom: 3%;
	right: 8%;
	font-size: 13px;
	color: black;
}

@media only screen and (max-width: 900px) {
	.card {
		padding-top: 10%;
	}
}